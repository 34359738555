import React from 'react'
import './styles/sampleQuestion.css'
import TypeWriter from 'typewriter-effect'


class SampleQuestion extends React.Component {
    constructor(props) {
        super(props)

    }
    render() {
        return (
            <div className="frontpage-preview-container">
            <div className="frontpage-preview-menu">
              <div className="frontpage-preview-menu-items">
                <div className="frontpage-preview-menu-item frontpage-preview-menu-item-1"></div>
                <div className="frontpage-preview-menu-item frontpage-preview-menu-item-2"></div>
                <div className="frontpage-preview-menu-item frontpage-preview-menu-item-3"></div>
              </div>
            </div>
         
            <div className="frontpage-preview-content-container">
            
                <div className="sample-question-container">
                    <div className="previous-question-container">
                        <p className="previous-question-subtitle">Uw vraag of opmerking:</p>
                        <p className="previous-question-content">{this.props.question}</p>
                    </div>
                    <div className="answer-container">
                        <h1 className="answer-subtitle">Antwoord</h1>
                        <p className="answer-content"><TypeWriter options={{strings: this.props.answer, autoStart: true, cursor: '', delay: 50}}/></p>
                    </div>
                </div>
            </div>
            
            </div>
        )
    }   
}

export default SampleQuestion;