import logo from "./logo.svg";
import "./App.css";
import SampleQuestion from "./components/sampleQuestion";
import React, { useState } from "react";
import emailjs from "@emailjs/browser";

import { addEmail, analyticsCounter } from "./api"; // Import the saveEmailToDatabase function
// import { Fade , Slide} from "react-awesome-reveal";

function App() {
  const handleButtonClick = async () => {
    console.log("BUTTON CLICK: ");
    const request = await analyticsCounter()
      .then((response) => {
        console.log("welldone");
        window.location.href = "https://app.asksenna.nl";
      })
      .catch((error) => {
        console.log("bizarre error");
        window.location.href = "https://app.asksenna.nl";
      });
  };

  return (
    <>
      <div className="App">
        <div className="header-container">
          <a className="header-link header-avioz" href="https://avioz.nl">
            Avioz
          </a>
          <div className="logo-container">
            <div className="logo-svg">
              <svg
                viewBox="0 0 86 82"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  shape-rendering="geometricPrecision"
                  d="M74.5 38.2202C74.5 38.2202 47.9463 5.67899 32 13.1007C10.4979 23.1082 77.9381 61.4179 55.5 69.1007C39.7185 74.5042 12 46.2202 12 46.2202"
                  stroke="#4478FF"
                  stroke-width="23"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <p className="logo-text">AskSenna</p>
          </div>
          <p></p>
        </div>
        <div className="frontpage-container-full-full">
          <div className="frontpage-container-full">
            <div className="frontpage-text-container">
              <h1 className="frontpage-text-title">
                <span>
                  Vragen over <br />
                  regels en wetgeving?{" "}
                </span>{" "}
              </h1>

              <a className="frontpage-button" onClick={handleButtonClick}>
                Probeer AskSenna
              </a>
            </div>

            <div className="frontpage-preview-container-full">
              <SampleQuestion
                question="Wat zijn de belangrijkste wettelijke vereisten en regelgeving waar Nederlandse startups rekening mee moeten houden bij het oprichten, financieren, en runnen van hun bedrijf, en hoe kunnen ze deze juridische aspecten effectief navigeren om succesvol te zijn in de Nederlandse startup-ecosfeer?"
                answer="Startups in Nederland moeten bij het oprichten en runnen van hun bedrijf rekening houden met wettelijke vereisten en regelgeving, waaronder het kiezen van de juiste rechtsvorm, het beschermen van intellectueel eigendom, belastingkwesties zoals de innovatiebox en startersaftrek, en arbeidsrechtelijke aspecten bij het aannemen van personeel."
              />
            </div>
          </div>
        </div>

        <div className="all-sections">
          <div className="section">
            <h1 className="section-header section-1-header">
              Wat is AskSenna?
            </h1>
            <div className="section-grid">
              <div className="section-element section-element-1">
                <div className="image-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#4478FF"
                    viewBox="0 -960 960 960"
                  >
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M240-252q-57-52-88.5-121.5T120-520q0-150 105-255t255-105q125 0 221.5 73.5T827-615l52 205q5 19-7 34.5T840-360h-80v120q0 33-23.5 56.5T680-160h-80v40q0 17-11.5 28.5T560-80q-17 0-28.5-11.5T520-120v-80q0-17 11.5-28.5T560-240h120v-160q0-17 11.5-28.5T720-440h68l-38-155q-23-91-98-148t-172-57q-116 0-198 81t-82 197q0 60 24.5 114t69.5 96l26 24v168q0 17-11.5 28.5T280-80q-17 0-28.5-11.5T240-120v-132Zm254-188Zm-14 120q17 0 28.5-11.5T520-360q0-17-11.5-28.5T480-400q-17 0-28.5 11.5T440-360q0 17 11.5 28.5T480-320Zm3-343q22 0 36.5 12t14.5 31q0 16-8.5 30.5T492-549q-19 20-27.5 35.5T453-478q-2 12 6.5 21t21.5 9q11 0 20.5-7.5T514-476q3-12 9.5-23.5T544-526q32-35 42-53t10-41q0-42-32.5-71T483-720q-32 0-60.5 15.5T377-662q-6 10-.5 21t16.5 16q11 5 21.5 1.5T433-638q10-12 23-18.5t27-6.5Z"
                    />
                  </svg>
                </div>
                <div className="text-container">
                  <h1>Jouw vraaghulp voor Startups en Regelgeving</h1>
                  <p>
                    AskSenna helpt startups en scale-ups bij het aanpakken van
                    hun uitdagingen. Zij kunnen er vragen stellen, of
                    belemmerende wetgeving aangeven.
                  </p>
                  <p>
                    Het dient niet alleen als een vraaghulp, maar brengt ook
                    opmerkingen over belemmerende wet- en regelgeving onder de
                    aandacht van het Ministerie van Economische Zaken en
                    Klimaat.
                  </p>
                </div>
              </div>

              <div className="section-element section-element-2">
                <div className="text-container">
                  <h1>Een platform om regelgeving in kaart te brengen </h1>
                  <p>
                    AskSenna brengt het netwerk van de overheid dichterbij de
                    ondernemer. We zorgen ervoor dat ondernemers snel antwoord
                    en duidelijkheid krijgen, waardoor ze hun innovatiekracht
                    behouden en kunnen voortbouwen op hun ideeën.
                  </p>
                </div>
              </div>

              <div className="section-element section-element-3">
                <div className="image-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#FFD027"
                    viewBox="0 -960 960 960"
                  >
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M480-80q-26 0-47-12.5T400-126q-33 0-56.5-23.5T320-206v-142q-59-39-94.5-103T190-590q0-121 84.5-205.5T480-880q121 0 205.5 84.5T770-590q0 77-35.5 140T640-348v142q0 33-23.5 56.5T560-126q-12 21-33 33.5T480-80Zm-80-126h160v-36H400v36Zm0-76h160v-38H400v38Zm-8-118h58v-108l-67-67q-9-9-9-21t9-21q9-9 21-9t21 9l55 55 55-55q9-9 21-9t21 9q9 9 9 21t-9 21l-67 67v108h58q54-26 88-76.5T690-590q0-88-61-149t-149-61q-88 0-149 61t-61 149q0 63 34 113.5t88 76.5Zm88-162Zm0-38Z"
                    />
                  </svg>
                </div>
                <h1>Een slimmere toekomst voor startups</h1>
              </div>
            </div>
          </div>

          <div className="section">
            {/* <h1 className="section-header section-2-header">De volgende stap</h1>
        <div className="section-grid">
          <div className="section-element section-element-1 section-element-4">

            <div className="text-container">
              <h1>Een nieuwe AskSenna</h1>
              <p>We vinden het belangrijk om ervoor te zorgen dat gebruikers
makkelijker hun vragen kunnen stellen en hun opmerkingen kunnen doorsturen als het gaat om wetgeving binnen het startupklimaat.
              </p>
              <p>

Daarom bouwen we aan een nieuwe versie van AskSenna.
              </p>

            </div>
            <div className="image-container">
              <img src="group1.png"/>
            </div>
          </div>

          <div className="section-grid-roadmap">
          <div className="section-element section-element-roadmap">
          <div className="image-container">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#4478FF" viewBox="0 -960 960 960"><path d="M760-200H320q-33 0-56.5-23.5T240-280v-560q0-33 23.5-56.5T320-920h247q16 0 30.5 6t25.5 17l194 194q11 11 17 25.5t6 30.5v367q0 33-23.5 56.5T760-200Zm0-440H620q-25 0-42.5-17.5T560-700v-140H320v560h440v-360ZM160-40q-33 0-56.5-23.5T80-120v-520q0-17 11.5-28.5T120-680q17 0 28.5 11.5T160-640v520h400q17 0 28.5 11.5T600-80q0 17-11.5 28.5T560-40H160Zm160-800v200-200 560-560Z"/></svg>
            </div>
              <div className="text-container">
              <p className="roadmap-subtitle">
              Regeldrukanalyse
               <br/>Startupklimaat NL
              </p>
              <p className="roadmap-date">Juni, 2023</p>

            </div>
          </div> */}

            {/* <div className="section-element section-element-roadmap">
          <div className="image-container">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#4478FF" viewBox="0 -960 960 960"><path d="M240-40q-50 0-85-35t-35-85q0-50 35-85t85-35q14 0 26 3t23 8l57-71q-28-31-39-70t-5-78l-81-27q-17 25-43 40t-58 15q-50 0-85-35T0-580q0-50 35-85t85-35q50 0 85 35t35 85v8l81 28q20-36 53.5-61t75.5-32v-87q-39-11-64.5-42.5T360-840q0-50 35-85t85-35q50 0 85 35t35 85q0 42-26 73.5T510-724v87q42 7 75.5 32t53.5 61l81-28v-8q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-32 0-58.5-15T739-515l-81 27q6 39-5 77.5T614-340l57 70q11-5 23-7.5t26-2.5q50 0 85 35t35 85q0 50-35 85t-85 35q-50 0-85-35t-35-85q0-20 6.5-38.5T624-232l-57-71q-41 23-87.5 23T392-303l-56 71q11 15 17.5 33.5T360-160q0 50-35 85t-85 35ZM120-540q17 0 28.5-11.5T160-580q0-17-11.5-28.5T120-620q-17 0-28.5 11.5T80-580q0 17 11.5 28.5T120-540Zm120 420q17 0 28.5-11.5T280-160q0-17-11.5-28.5T240-200q-17 0-28.5 11.5T200-160q0 17 11.5 28.5T240-120Zm240-680q17 0 28.5-11.5T520-840q0-17-11.5-28.5T480-880q-17 0-28.5 11.5T440-840q0 17 11.5 28.5T480-800Zm0 440q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm240 240q17 0 28.5-11.5T760-160q0-17-11.5-28.5T720-200q-17 0-28.5 11.5T680-160q0 17 11.5 28.5T720-120Zm120-420q17 0 28.5-11.5T880-580q0-17-11.5-28.5T840-620q-17 0-28.5 11.5T800-580q0 17 11.5 28.5T840-540ZM480-840ZM120-580Zm360 120Zm360-120ZM240-160Zm480 0Z"/></svg>
            </div>
              <div className="text-container">
              <p className="roadmap-subtitle">
              Lancering <br/>innovationhub netwerk

              </p>
              <p className="roadmap-date">September, 2023</p>

            </div>
          </div>
          <div className="section-element section-element-roadmap">
          <div className="image-container">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#4478FF" viewBox="0 -960 960 960"><path d="M319-230q-10-29-18.5-59T287-349l-47 32v119l79-32Zm147-535q-48 51-77 134.5T360-455q0 60 11 117.5t29 97.5h160q18-40 29-97.5T600-455q0-92-29-175.5T494-765q-3-3-6.5-4.5T480-771q-4 0-7.5 1.5T466-765Zm14 325q-33 0-56.5-23.5T400-520q0-33 23.5-56.5T480-600q33 0 56.5 23.5T560-520q0 33-23.5 56.5T480-440Zm161 210 79 32v-119l-47-32q-5 30-13.5 60T641-230ZM511-856q84 72 126.5 177T680-440l84 56q17 11 26.5 29t9.5 38v178q0 21-17.5 33t-37.5 4l-144-58H359l-144 58q-20 8-37.5-4T160-139v-178q0-20 9.5-38t26.5-29l84-56q0-134 42.5-239T449-856q7-5 15-8t16-3q8 0 16 3t15 8Z"/></svg>
            </div>
              <div className="text-container">
              <p className="roadmap-subtitle">
              AskSenna<br/>
              Beta Launch
              </p>
              <p className="roadmap-date">December, 2023</p>

            </div>
          </div> */}

            {/* <div className="section-element section-element-roadmap">
          <div className="image-container">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#4478FF" viewBox="0 -960 960 960"><path d="M284-506q14-28 29-54t33-52l-56-11-84 84 78 33Zm482-275q-70 2-149.5 41T472-636q-42 42-75 90t-49 90l114 113q42-16 90-49t90-75q65-65 104-144t41-149q0-4-1.5-8t-4.5-7q-3-3-7-4.5t-8-1.5ZM546-541q-23-23-23-56.5t23-56.5q23-23 57-23t57 23q23 23 23 56.5T660-541q-23 23-57 23t-57-23Zm-34 262 33 79 84-84-11-56q-26 18-52 32.5T512-279Zm351-534q8 110-36 214.5T688-399l20 99q4 20-2 39t-20 33L560-102q-15 15-36 11.5T495-114l-61-143-171-171-143-61q-20-8-24-29t11-36l126-126q14-14 33.5-20t39.5-2l99 20q95-95 199.5-139T819-857q8 1 16 4.5t14 9.5q6 6 9.5 14t4.5 16ZM157-321q35-35 85.5-35.5T328-322q35 35 34.5 85.5T327-151q-25 25-83.5 43T82-76q14-103 32-161.5t43-83.5Zm57 56q-10 10-20 36.5T180-175q27-4 53.5-13.5T270-208q12-12 13-29t-11-29q-12-12-29-11.5T214-265Z"/></svg>
            </div>
              <div className="text-container">
              <p className="roadmap-subtitle">
              AskSenna <br/>
              Officiele Launch
              </p>
              <p className="roadmap-date">Februari, 2024</p>

            </div>
          </div>


          <div className="section-element section-element-roadmap">
          <div className="image-container">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#4478FF" viewBox="0 -960 960 960"><path d="M320-160q-17 0-28.5-11.5T280-200q0-17 11.5-28.5T320-240h320q17 0 28.5 11.5T680-200q0 17-11.5 28.5T640-160H320Zm160-160q-17 0-28.5-11.5T440-360v-287l-76 75q-11 11-27.5 11.5T308-572q-11-11-11-28t11-28l144-144q6-6 13-8.5t15-2.5q8 0 15 2.5t13 8.5l144 144q11 11 11.5 27.5T652-572q-11 11-28 11t-28-11l-76-75v287q0 17-11.5 28.5T480-320Z"/></svg>
            </div>
              <div className="text-container">
              <p className="roadmap-subtitle">
              <br/>

              Uitbreidingen
              </p>
              <p className="roadmap-date">April, 2024</p>

            </div>
          </div>


          </div>

          <div className="section-element section-element-1 section-element-cta">

            <div className="text-container">
              <h1>Schrijf je in voor de beta!</h1>
              <p>Op 10 december lanceren we de beta van de vernieuwde AskSenna! <br/> Schrijf je in voor de beta om er als eerste bij te zijn!
              </p>
              <a className="frontpage-button" style={{backgroundColor:'white', color: 'black'}}href="https://app.asksenna.nl">Probeer AskSenna</a>
            </div>
          </div>

        </div>
        </div> */}

            <div className="section-element section-element-1 section-element-cta">
              <div className="text-container">
                <h1>Uw vraag beantwoord</h1>
                <p style={{ maxWidth: "60%" }}>
                  Met AskSenna heb je sneller antwoord op uw vraag over regel-
                  en wetgeving én help je tegelijkertijd de overheid met het
                  vormen van een beter startup klimaat.
                </p>
                <a
                  className="frontpage-button"
                  style={{ backgroundColor: "white", color: "black" }}
                  href="https://app.asksenna.nl"
                >
                  Probeer AskSenna
                </a>
              </div>
            </div>
          </div>
          <div className="section">
            <h1 className="section-header">Over ons</h1>
            <div className="section-grid">
              <div className="section-element section-element-1 ">
                <div className="text-container">
                  <h1>Wij zijn Avioz</h1>
                  <p>
                    Avioz pakt de uitdaging aan om belemmerende wet- en
                    regelgeving voor startups te doorgronden. <br />
                    <br />
                    Hun divers team heeft een intelligent platform gebouwd dat
                    data verzamelt, analyseert en de inzichten deelt met
                    relevante stakeholders. <br />
                    <br />
                    Daarnaast focust Avioz op slimme technologie, zoals AI, om
                    besluitvorming te optimaliseren en bij te dragen aan de
                    ontwikkeling van Smart Cities. Een stap richting een
                    slimmere toekomst voor startups.
                  </p>
                  <p>
                    Meer weten? <a href="https://avioz.nl">Klik hier!</a>
                  </p>
                </div>
                <div className="image-container">
                  <img className="avioz-img" src="avioz.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <div className="footer-text-container">
            <p>Wil je feedback geven? Graag!</p>
            <a
              className="feedback-button"
              href="https://tripetto.app/run/5XEFSP5RGM "
            >
              Klik hier
            </a>
            <p>of</p>
            <a className="link-nostyle" href="https://avioz.nl/#section_HLln3">
              Contact
            </a>

            <div className="divider-line"></div>

            <div className="medemogelijk-container">
              <p>
                AskSenna is gestart met hulp van het RVO. <br />
                We willen het startup-klimaat in Nederland verbeteren <br />
                en de regeldruk voor early stage bedrijven en startups
                verminderen.
              </p>
              <div className="medemogelijk-images">
                <img src="ezk-logo.png"></img>
                <img src="intergov-png.png"></img>
              </div>
            </div>
            <h4 className="avioz-footer">
              ©{" "}
              <a className="link-nostyle" href="https://avioz.nl">
                Avioz
              </a>{" "}
              - 2023
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
