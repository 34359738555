// api.js
// const serverEndpoint = 'http://sarahcoste.com:3001';
// const serverEndpoint = 'http://37.251.0.202:3001' // raspberry pi
const serverEndpoint = "https://test-api.asksenna.nl";

// export const addEmail = async (email) => {
//     try {
//       const response = await fetch(`${serverEndpoint}/api/addEmail`, {
//         // mode: 'no-cors',
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       if (response.ok) {
//         return 'Email saved successfully.';
//       } else {
//         throw new Error('Email not saved');
//       }
//     } catch (error) {
//       console.error(error);
//       throw new Error('Failed everything email');
//     }
//   };

export const analyticsCounter = async () => {
  try {
    const response = await fetch(`${serverEndpoint}/asksenna_counter`, {
      // mode: 'no-cors',
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return { status: 200 };
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    console.error(error);
  }
};
